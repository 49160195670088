<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${warehouse.name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("WAREHOUSES.WAREHOUSE_IS_MODEL") }}</dt>
          <dd class="col-sm-8" v-if="warehouse.is_model">
            {{ $t("COMMON.YES") }}
          </dd>
          <dd class="col-sm-8" v-else>
            {{ $t("COMMON.NO") }}
          </dd>
        </dl>

        <dl class="row" v-if="!warehouse.is_model">
          <dt class="col-sm-4">{{ $t("WAREHOUSES.APPLIED_AT") }}</dt>
          <dd class="col-sm-8">
            <span v-if="warehouse.applied_at">
              {{ $formatDate(warehouse.applied_at) }}
            </span>
          </dd>
        </dl>

        <dl class="row" v-if="warehouse.modelUsed">
          <dt class="col-sm-4">{{ $t("WAREHOUSES.WAREHOUSE_MODEL") }}</dt>
          <dd class="col-sm-8">
            <router-link
              :to="{
                name: 'View Warehouse',
                params: { id: warehouse.modelUsed.id },
              }"
            >
              {{ warehouse.modelUsed.name }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("WAREHOUSES.ALLOW_NEGATIVE_INVENTORY") }}
          </dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!warehouse.allow_negative_inventory" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("WAREHOUSES.ALLOW_UNREGISTERED_PRODUCTS") }}
          </dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!warehouse.allow_unregistered_products" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("WAREHOUSES.USE_WAREHOUSE_TAXES") }}
          </dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!warehouse.use_warehouse_taxes" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.DEFAULT_TAX_GROUPS") }}</dt>
          <dd class="col-sm-8">
            <tax-groups :taxGroups="warehouse.taxGroups" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="warehouse.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="warehouse.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="warehouse.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(warehouse.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(warehouse.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";
import TaxGroups from "@/components/TaxGroups.vue";

export default {
  name: "warehouse-view-global",

  props: ["warehouse"],

  components: { IconCheck, TaxGroups },

  data() {
    return {};
  },

  computed: {
    warehouseableName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.warehouse.warehouseable.company_name;
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.warehouse.warehouseable.customer_name;
      }
      return "N/A";
    },
    warehouseableTypeName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    warehouseUpdated() {
      this.$emit("warehouseUpdated", true);
    },
  },

  mounted() {},

  watch: {
    warehouse(warehouse) {},
  },
};
</script>
