<template>
  <div class="row">
    <div
      class="col-10"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PRODUCTS)"
    >
      <base-input :placeholder="$t('COMMON.PRODUCT')">
        <products-selector
          :filterProductsNotInWarehouseId="warehouse.id"
          includes="warehouseProducts"
          :filterOrganization="filterOrganization"
          :products="productList"
          @productChanged="productsChange"
        />
      </base-input>
    </div>

    <div class="col-2">
      <el-button
        :disabled="!productList.length"
        type="success"
        class="w-100 px-1"
        @click="addBulkProducts"
      >
        {{ $t("COMMON.ADD_PRODUCTS") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Button, Option, Select } from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ProductsSelector from "@/components/ProductsSelector";

export default {
  name: "warehouse-add-bulk-products-form",

  components: {
    ProductsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    warehouse: {
      type: Object,
      default: null,
      description: "Warehouse",
    },
  },

  data() {
    return {
      warehouseProducts: [],
      filteredWarehouseProducts: [],
      products: [],
      productList: [],
      warehouseProductsModel: {},
      loading: true,
      submitting: false,
      selectedProduct: null,
    };
  },

  mounted() {},

  computed: {},

  watch: {
    warehouse: {
      handler: "getList",
      immediate: true,
    },
    filterOrganization: {
      handler: "getList",
      immediate: true,
    },
  },

  methods: {
    async saveWarehouseProducts() {
      const lines = Object.values(this.warehouseProductsModel);

      if (!lines?.length) {
        this.$notify({
          type: "warning",
          message: this.$t("PRODUCTS.EMPTY_ADD_PRODUCTS"),
        });
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        this.submitting = true;
        for (const line of lines) {
          const warehouseProductData = _.cloneDeep(line);
          if (
            warehouseProductData.product.id &&
            warehouseProductData.selling_price &&
            warehouseProductData.buying_price
          ) {
            delete warehouseProductData.id;
            await this.$store.dispatch(
              "warehouseProducts/add",
              warehouseProductData
            );
          }
        }
        this.products = [];
        this.productList = [];
        this.warehouseProductsModel = {};
        this.$emit("productsAdded");
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCTS_SAVED"),
        });
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "product,warehouse",
        };

        if (this.warehouse) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouse: this.warehouse.id,
            },
          };
        }

        await this.$store.dispatch("warehouseProducts/list", params);
        this.filteredWarehouseProducts =
          this.$store.getters["warehouseProducts/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    addLinesWithSupplierProduct(supplierProduct) {
      let newLinesIds = Object.values(this.warehouseProducts)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newWarehouseProduct = {
        type: "warehouse-products",
        id: newLineId,
        sku: supplierProduct ? supplierProduct.sku : null,
        selling_price: supplierProduct ? supplierProduct.selling_price : 0,
        buying_price: supplierProduct ? supplierProduct.buying_price : 0,
        buying_taxes: supplierProduct?.buying_taxes ?? [],
        selling_taxes: supplierProduct?.selling_taxes ?? [],
        relationshipNames: ["supplier", "product", "warehouse"],
        supplier: {
          type: "suppliers",
          id: this.selectedSupplier ?? null,
        },
        product: {
          type: "products",
          id: supplierProduct ? supplierProduct.product_id : null,
        },
        warehouse: {
          type: "warehouses",
          id: this.warehouse ? this.warehouse.id : null,
        },
      };
      const exists = this.warehouseProducts.some((existingProduct) => {
        return (
          existingProduct.supplier.id === newWarehouseProduct.supplier.id &&
          existingProduct.product.id === newWarehouseProduct.product.id &&
          existingProduct.warehouse.id === newWarehouseProduct.warehouse.id
        );
      });
      if (!exists) {
        this.warehouseProducts.push(_.cloneDeep(newWarehouseProduct));
        this.warehouseProductsModel[newLineId] =
          _.cloneDeep(newWarehouseProduct);
      }
    },

    productsChange(productIds, products) {
      this.products = productIds;
      this.productList = products;
    },

    async addBulkProducts() {
      await this.productList?.map((product) => {
        product?.supplierProducts.map((supplierProduct) => {
          const canNotBeAdded = this.filteredWarehouseProducts.find((item) => {
            return (
              item.warehouse?.id === this.warehouse.id &&
              item.product?.id === supplierProduct.product_id
            );
          });
          if (!canNotBeAdded) {
            this.addLinesWithSupplierProduct(supplierProduct);
          }
        });
      });
      await this.saveWarehouseProducts();
    },
  },
};
</script>
