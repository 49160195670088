<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-8">
        {{ `${warehouse.name}` }} - {{ $t("COMMON.WAREHOUSE") }}
      </span>
      <div class="col-4 text-right">
        <base-button
          @click="applyConfiguration()"
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{ $t("COMMON.APPLY_CONFIGURATION") }}
        </base-button>
      </div>
    </div>
    <div class="col-12">
      <form ref="profile_form" class="" @submit.prevent="addWarehouse">
        <div class="row">
          <base-input
            :label="`${$t('WAREHOUSES.WAREHOUSE_TO_CONFIGURE')}`"
            :placeholder="$t('WAREHOUSES.WAREHOUSE_TO_CONFIGURE')"
            class="col-11"
          >
            <warehouses-selector
              :allowNone="false"
              :showAll="false"
              :isOnlyModels="false"
              :filterIdsNotIn="usedWarehouses"
              :warehouses="selectedWarehouses.map((item) => item.id)"
              :includes="'modelUsed,organization'"
              @warehouseChanged="
                (warehouses) => {
                  selectedWarehouses = warehouses;
                }
              "
            />
          </base-input>
          <base-button
            type="button"
            class="btn btn-sm btn-primary my-4 col-1"
            native-type="submit"
            :disabled="loading || selectedWarehouses.length == 0"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ $t("COMMON.ADD") }}
          </base-button>
        </div>
      </form>
    </div>

    <warehouse-list-table
      v-if="!loading"
      :customRemove="true"
      :modelUsed="warehouse.id"
      :filterOrganization="warehouse.organization.id"
      @removeActionHandle="detachWarehouse"
    />

    <modal
      :show.sync="showResultsModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div>
        <h2>
          {{ $t("WAREHOUSES.CONFIGURATION_RESULTS") }}
        </h2>
        <div>
          <el-table
            :data="configurationResult"
            style="width: 100%"
            type="expand"
            row-key="id"
          >
            <el-table-column prop="line" :label="$t('COMMON.WAREHOUSE')">
              <template v-slot="{ row }">
                {{ row.name }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('WAREHOUSES.PRODUCTS_CREATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.results.CREATED}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('WAREHOUSES.PRODUCTS_UPDATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.results.UPDATED}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('WAREHOUSES.PRODUCTS_DELETED')"
            >
              <template v-slot="{ row }">
                {{ `${row.results.DELETED}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('WAREHOUSES.PRODUCTS_ERRORS')"
            >
              <template v-slot="{ row }">
                {{ `${row.results.ERRORS.length}` }}
              </template>
            </el-table-column>

            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table
                  :data="props.row.results.ERRORS"
                  style="width: 100%"
                  type="expand"
                  row-key="id"
                >
                  <el-table-column prop="id" :label="$t('WAREHOUSES.PRODUCT')">
                    <template v-slot="{ row }">
                      {{ `${row.product.name}` }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" :label="$t('WAREHOUSES.ERROR')">
                    <template v-slot="{ row }">
                      {{ row.message }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="configurationResult.length"
          ></el-pagination>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import {
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
} from "element-ui";
import WarehousesSelector from "@/components/WarehousesSelector.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import WarehouseListTable from "./WarehouseListTable";

export default {
  name: "warehouse-view-warehouse-configured",

  components: {
    WarehouseListTable,
    WarehousesSelector,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  props: ["warehouse"],

  mixins: [requestErrorMixin],

  data() {
    return {
      loading: false,
      showResultsModal: false,
      usedWarehouses: this.warehouse.usedBy.map((item) => item.id),
      configurationResult: [],
      selectedWarehouses: [],
    };
  },

  computed: {},

  methods: {
    async addWarehouse() {
      this.loading = true;
      try {
        for (const warehouse of this.selectedWarehouses) {
          const warehouseData = cloneDeep(warehouse);
          warehouseData.modelUsed = this.warehouse;
          await this.$store.dispatch("warehouses/update", warehouseData);
        }
        this.selectedWarehouses = [];
        this.loading = false;
        this.$notify({
          type: "success",
          message: this.$t("WAREHOUSES.WAREHOUSE_UPDATED"),
        });

        this.$emit("warehouseUpdated");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async detachWarehouse(warehouse) {
      this.loading = true;
      try {
        const warehouseData = cloneDeep(warehouse);
        warehouseData.modelUsed = null;
        await this.$store.dispatch("warehouses/update", warehouseData);

        this.selectedWarehouses = [];
        this.loading = false;
        this.$notify({
          type: "success",
          message: this.$t("WAREHOUSES.WAREHOUSE_UPDATED"),
        });

        this.$emit("warehouseUpdated");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async applyConfiguration() {
      const confirmation = await swal.fire({
        title: this.$t("WAREHOUSES.APPLY_CONFIGURATION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const results = await this.$store.dispatch(
          "warehouses/applyConfiguration",
          this.warehouse.id
        );
        this.configurationResult = results;
        this.showResultsModal = true;
        swal.close();

        this.$emit("warehouseUpdated");
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {
    warehouse(warehouseData) {
      this.usedWarehouses = this.warehouse.usedBy.map((item) => item.id);
    },
  },
};
</script>
